<template>
    <main>
        <component @view="changeComponent" :pengembalian="item" :is="currentComponent"></component>
    </main>
</template>
<script>
import DepositReceived from './ListSetoran.vue'
import DetailPengembalian from './DetailPengembalian.vue'
export default {
    components: {
        DepositReceived,
        DetailPengembalian
    },
    data: () => ({
        currentComponent: 'DepositReceived',
        item: {}
    }),
    methods: {
        changeComponent({component, pengembalian}) {
            this.item = pengembalian
            this.currentComponent = component
        }
    }
}
</script>